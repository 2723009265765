import Shop from './components/shop';
import Checkout from './components/checkout';
import Payment from './components/payment';
import Home from './components/home';
import About from './components/about';
import Contact from './components/contact';

import SHeader from './components/header';
import SFooter from './components/footer';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
        <div id="page">
        <div id="contentwrap">
        <SHeader/>
        <Router>
            <Routes>
                <Route path='/checkout' element={<Checkout/>}/>
                <Route path='/payment' element={<Payment/>}/>
                <Route path='/shop' element={<Shop/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/contact' element={<Contact/>}/>
                <Route path='/' element={<Home/>}/>
            </Routes>
        </Router>
        <SFooter/>
        </div>
        </div>
  );
}

export default App;
