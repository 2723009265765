import '../App.css';
import {useNavigate} from "react-router-dom";

function About() {
    const navigate = useNavigate(); // <-- use hook in component
    
    return (
        <>    
        <section className="contact-area">
            <div className="titleArea d-flex align-items-center justify-content-center mb-30">
                        <img src={`${process.env.PUBLIC_URL +'/img/core-img/contact.png'}`}/>
            </div>
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-lg-5">
                        <div className="section-heading">
                        </div>
                        <div className="contact-form-area mb-100">
                            <form id="myForm" action="mail.php" method="post">
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="contact-name" name="contact-name" placeholder="Your Name"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group">
                                            <input type="email" className="form-control" id="contact-email"  name="contact-email" placeholder="Your Email"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="contact-subject"  name="contact-subject" placeholder="Subject"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea className="form-control" name="message" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn alazea-btn mt-15">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        </>
    );
}

export default About;
