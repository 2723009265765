import React, { useState } from 'react';
import {useDispatch} from 'react-redux';
import {addToCart} from "../actions/actionCreators";
import { notification } from 'antd';

function Product({item}) {
    
    const [api, contextHolder] = notification.useNotification();
    const [qty, setQty] = useState(0);
    const [size, setSize] = useState(null);
    const dispatch = useDispatch();
    
    const setSizeData =(e)=>{
        setSize(e.target.value);
    }
    
    const setQtyData =(e)=>{
        setQty(e.target.value);
    }
    
    const addItem =()=>{
        if (qty > 0 && size !== null)
        {
            const data = {
                    id: item.id
                    ,unitName: item.unitName
                    ,qty: parseInt(qty)
                    ,size: "N/A"
                    ,cost: parseInt(item.cost)
            };
            dispatch(addToCart(data));  
            
            api.info({
                  message: `Item added`,
                  description:`${qty} ${item.unitName} added to shopping cart`,
                  placement: 'bottomLeft',
            });
            
        } 
        else
        {
            api.error({
                  message: `Item not added`,
                  description:`qty or size not selected`,
                  placement: 'bottomLeft',
            });
        }
    }
    
  return (
        <>        
      {contextHolder}
        <div className="box p-3 mt-3">
            <div className="row d-flex justify-content-center  mb-3">   
                <img src={item.img}/> 
            </div>  
            <div className="row price d-flex justify-content-center">   
                  {item.unitName}
            </div>  
            <div className="row description justify-content-end p-3">   
                  {item.description}
            </div>  
            <div className="row d-flex justify-content-center mb-3">   
                  Full price: ${(item.cost).toLocaleString()} GUSD
            </div>  
            <div className="row price d-flex justify-content-center mb-1">   
                  ${item.reserve} GUSD to reserve.
            </div>  
        </div> 
    </>
  );
}

export default Product;
