/*
 * action types
 */

export const ACTIONS = {    	
	//data
	ADD_TO_CART			 : 'ADD_TO_CART'
	,CLEAR_CART			 : 'CLEAR_CART'
    ,REMOVE_FROM_CART    : 'REMOVE_FROM_CART'
    ,STORE_ADDRESS       : 'STORE_ADDRESS'
    ,CONFIRM_PAYMENT     : 'CONFIRM_PAYMENT'
    ,SET_WALLET              : 'SET_WALLET'
    ,CLEAR_WALLET            : 'CLEAR_WALLET'
    ,SET_TRANSACTION         : 'SET_TRANSACTION'
    ,CLEAR_TRANSACTION       : 'CLEAR_TRANSACTION'
    ,SET_LOGIN_STATUS        : 'SET_LOGIN_STATUS'
    ,CLEAR_LOGIN_STATUS      : 'CLEAR_LOGIN_STATUS'
    
	
	,LOGIN					 : 'LOGIN'
	,CREATE_USER	    	 : 'CREATE_USER'
	,SET_LOGIN_STATE		 : 'SET_LOGIN_STATE'
    
    
    ,CREATE_PAYMENT_TRANSACTION : 'CREATE_PAYMENT_TRANSACTION'
	
 }
 
export const STATUS = {OPEN :'OPEN', CLOSE : 'CLOSE', ERROR :'ERROR'
                       , PENDING: 'PENDING', INITIAL: 'INITIAL', ACTIVE: 'ACTIVE' 
                       , RESET: 'RESET', START:'START', PAUSE: 'PAUSE', STOP: 'STOP'
                       , RESTART: 'RESTART', SAVE: 'SAVE', END: 'END', RESULTS: 'RESULTS'
                       };
export const VARIANT = {INFO :'info', SUCCESS : 'success', ERROR :'error'
                       , WARNING: 'warning'
                       };


                       
export const HANDLER = {WS :'WS', ALL : 'ALL'};
export const ENV = {MAINNET :"MAINNET", DEV : "DEV", TESTNET: "TESTNET"};