import '../App.css';
import Product from './product';
import {ProductList} from '../assets/productlist';
import { useSelector, useDispatch} from 'react-redux';
import {useNavigate} from "react-router-dom";
import { notification } from 'antd';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {addToCart} from "../actions/actionCreators";

function Home() {
    const navigate = useNavigate(); // <-- use hook in component
    const [api, contextHolder] = notification.useNotification();
    
    const dispatch = useDispatch();  
    const founder = ProductList['founder'];
    
    const checkout =()=>{
        addItem();
        navigate("/checkout");
    };
   
    const addItem =()=>{
        const data = {
                id: founder.id
                ,unitName: founder.unitName
                ,qty: 1
                ,size: "N/A"
                ,cost: parseInt(founder.reserve)
        };
        dispatch(addToCart(data)); 
    }
    
    return (
        <>        
        {contextHolder}
    <section className="hero-area">
                <div className="alazea-video-area">
                <video autoPlay={true} loop muted playsInline width="100%">
                    <source src={`${process.env.PUBLIC_URL +'/video/webtrailer.mp4'}`}
                            type="video/mp4"/>
                </video>
            </div>
    </section>
    
    <section className="hero-area">
        <OwlCarousel className='owl-theme hero-post-slides' dots={false} loop items={1} autoplay={true} autoplayTimeout={3000}>

            <div className="single-hero-post bg-overlay">
                <div className="slide-img bg-img" style={{"backgroundImage": `url(${process.env.PUBLIC_URL +'/img/bg-img/1.JPG'})`}}></div>
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-around">
                        <div>
                            <img src={`${process.env.PUBLIC_URL +'/img/core-img/100electric.png'}`}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="single-hero-post bg-overlay">
                <div className="slide-img bg-img" style={{"backgroundImage": `url(${process.env.PUBLIC_URL +'/img/bg-img/2.JPG'})`}}></div>
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-around">
                        <div>
                            <img src={`${process.env.PUBLIC_URL +'/img/core-img/secured.png'}`}/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="single-hero-post bg-overlay">
                <div className="slide-img bg-img" style={{"backgroundImage": `url(${process.env.PUBLIC_URL +'/img/bg-img/6.JPG'})`}}></div>
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-around">
                        <div>
                            <img src={`${process.env.PUBLIC_URL +'/img/core-img/owners.png'}`}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="single-hero-post bg-overlay">
                <div className="slide-img bg-img" style={{"backgroundImage": `url(${process.env.PUBLIC_URL +'/img/bg-img/3.JPG'})`}}></div>
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-around">
                        <div>
                            <img src={`${process.env.PUBLIC_URL +'/img/core-img/radical.png'}`}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="single-hero-post bg-overlay">
                <div className="slide-img bg-img" style={{"backgroundImage": `url(${process.env.PUBLIC_URL +'/img/bg-img/4.JPG'})`}}></div>
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-around">
                        <div>
                            <img src={`${process.env.PUBLIC_URL +'/img/core-img/built.png'}`}/>
                        </div>
                    </div>
                </div>
            </div>
        </OwlCarousel>
    </section>
    
    <section className="our-services-area bg-gray section-padding-100-0">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-heading text-center">
                        <img src={`${process.env.PUBLIC_URL +'/img/core-img/technology.png'}`}/>
                        <p>Uncompromising beauty meets functionality.</p>
                    </div>
                </div>
            </div>

            <div className="row justify-content-between">
                <div className="col-12 col-lg-5">
                    <div className="alazea-service-area mb-100">

                        <div className="single-service-area d-flex align-items-center wow fadeInUp" data-wow-delay="100ms">
                            <div className="service-content">                                
                                <img src={`${process.env.PUBLIC_URL +'/img/core-img/motorcycle.png'}`}/>
                                <p>The Strike motorcycles are street legal electric motorcycles. We took design cues from custom motorcycles, but also added a lot of our own flare to make this a first of its kind in many ways.</p>
                                <p>The "gas tank" is actually a removable backpack that doesn't just serve as storage but is easily detached by the rider and used like a regular backback.</p>
                                <p>The solid wheels help reduce aerodynamic drag while also providing a modern custom look.</p>
                                <p> The 7" display is a console that offers all features that luxury automobile drivers are accustomed to, but almost non-existent in motorcycles.</p>
                                <p> Bluetooth connectivity, USB charging, GPS, and WiFi capability are some of the many features that come standard on our motorcycles.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                        <div className="single-service-area d-flex align-items-center wow fadeInUp" data-wow-delay="100ms">
                            <div className="service-content">
                                <img src={`${process.env.PUBLIC_URL +'/img/core-img/blockchain.png'}`}/>
                                <p>Each bike comes with a cryptographically secure token that's registered on the Solana blockchain. The token not only serves as proof of ownership but also as a security device.<br/>
                                If the bike is stolen, ownership cannot be transferred anywhere in the world as the true owner retains the token.</p> 
                                <p> The token can be used to transfer ownership when the bike is sold; thereby, unlocking some security features to the new owner. The token will also serve as a key for technology updates that are planned in the future.</p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </section>
    
    <section className="about-us-area section-padding-100-0">
        <div className="container">
                    <div className="section-heading  text-center">
                        <img src={`${process.env.PUBLIC_URL +'/img/core-img/models.png'}`}/>
                        <p>All prices before government incentives</p>
                            <div className="text-center mt-15">Resevations payments using <a href="https://goodusd.com" target="_blank"><img src={`${process.env.PUBLIC_URL +'/img/core-img/gooddollar.png'}`}/></a> stablecoin and payment system is now available for <img style={{cursor: "pointer"}} src={`${process.env.PUBLIC_URL +'/img/core-img/founders.png'}`} onClick={checkout}/>
                            </div>
                    </div>
					<div>	
						<div className="row">
							<div className="col-3 d-none d-lg-block">	
								<ul>
									<li><b>Model</b></li>
									<li><b>Price</b></li>
									<li><b>Top speed</b></li>
									<li><b>Range</b></li>
									<li><b>Power</b></li>
									<li><b>Battery</b></li>
									<li><b>Motor</b></li>
									<li><b>Charge type</b></li>
									<li><b>Charge time</b></li>
									<li><b>Dash</b></li>
								</ul>
							</div>
							<div className="col-3">	
								<ul>
									<li><a href="#"><b>M5 Version</b></a></li>
									<li><a href="#">$4,999 USD</a></li>
									<li><a href="#">75mph</a></li>
									<li><a href="#">100miles</a></li>
									<li><a href="#">5KW</a></li>
									<li><a href="#">72V/ 60A</a></li>
									<li><a href="#">BLDC</a></li>
									<li><a href="#">110/220V</a></li>
									<li><a href="#">0-100% 5hrs</a></li>
									<li><a href="#">7" display</a></li>
								</ul>
							</div>
							<div className="col-3">	
								<ul>
									<li><a href="#"><b>M7 Version</b></a></li>
									<li><a href="#">$7,999 USD</a></li>
									<li><a href="#">88mph</a></li>
									<li><a href="#">120miles</a></li>
									<li><a href="#">7.5KW</a></li>
									<li><a href="#">72V/ 80A</a></li>
									<li><a href="#">BLDC</a></li>
									<li><a href="#">110/220V</a></li>
									<li><a href="#">0-100% 5hrs</a></li>
									<li><a href="#">7" display</a></li>
								</ul>
							</div>
							<div className="col-3">	
								<ul>
									<li><a href="#"><b>Founder's Edition (limited to 100)</b></a></li>
									<li><a href="#">$15,000 USD</a></li>
									<li><a href="#">110mph</a></li>
									<li><a href="#">150miles</a></li>
									<li><a href="#">15KW</a></li>
									<li><a href="#">72V/ 120A</a></li>
									<li><a href="#">BLDC</a></li>
									<li><a href="#">110/220V</a></li>
									<li><a href="#">0-100% 5hrs</a></li>
									<li><a href="#">7" display</a></li>
                                    <li><button onClick={checkout} className=" alazea-btn-sm mt-15">reserve</button></li>
								</ul>
							</div>
						</div>
					</div>
        </div>
    </section>
    
    <section className="alazea-portfolio-area section-padding-100-0">
        <div className="container">
        </div>

        <div className="container-fluid">
            <div className="row alazea-portfolio">

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 single_portfolio_item ICE wow fadeInUp" data-wow-delay="100ms">
                    <div className="portfolio-thumbnail bg-img" style={{"backgroundImage": `url(${process.env.PUBLIC_URL +'/img/bg-img/16.JPG'})`}}></div>
                    <div className="portfolio-hover-overlay">
                        <a href={process.env.PUBLIC_URL +'/img/bg-img/16.JPG'} className="portfolio-img d-flex align-items-center justify-content-center" title="Portfolio 1">
                            <div className="port-hover-text">
                                <h3>view larger</h3>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 single_portfolio_item ICE wow fadeInUp" data-wow-delay="400ms">
                    <div className="portfolio-thumbnail bg-img" style={{"backgroundImage": `url(${process.env.PUBLIC_URL +'/img/bg-img/18.JPG'})`}}></div>
                    <div className="portfolio-hover-overlay">
                        <a href={process.env.PUBLIC_URL +'/img/bg-img/18.JPG'} className="portfolio-img d-flex align-items-center justify-content-center" title="Portfolio 4">
                            <div className="port-hover-text">
                                <h3>view larger</h3>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 single_portfolio_item ICE wow fadeInUp" data-wow-delay="200ms">
                    <div className="portfolio-thumbnail bg-img" style={{"backgroundImage": `url(${process.env.PUBLIC_URL +'/img/bg-img/17.JPG'})`}}></div>
                    <div className="portfolio-hover-overlay">
                        <a href={process.env.PUBLIC_URL +'/img/bg-img/17.JPG'} className="portfolio-img d-flex align-items-center justify-content-center" title="Portfolio 2">
                            <div className="port-hover-text">
                                <h3>view larger</h3>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 single_portfolio_item ICE design wow fadeInUp" data-wow-delay="300ms">
                    <div className="portfolio-thumbnail bg-img" style={{"backgroundImage": `url(${process.env.PUBLIC_URL +'/img/bg-img/28.JPG'})`}}></div>
                    <div className="portfolio-hover-overlay">
                        <a href={process.env.PUBLIC_URL +'/img/bg-img/28.JPG'} className="portfolio-img d-flex align-items-center justify-content-center" title="Portfolio 3">
                            <div className="port-hover-text">
                                <h3>view larger</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section className="contact-area section-padding-100-0">
        <div className="container">
            <div className="row align-items-center justify-content-between">
                <div className="col-12 col-lg-5">
                    <div className="section-heading">                        
                        <img src={`${process.env.PUBLIC_URL +'/img/core-img/getintouch.png'}`}/>
                    </div>
                    <div className="contact-form-area mb-100">
						<form id="myForm" action={`${process.env.PUBLIC_URL + '/mail.php'}`} method="post">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="contact-name" name="contact-name" placeholder="Your Name"/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <input type="email" className="form-control" id="contact-email"  name="contact-email" placeholder="Your Email"/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="contact-subject"  name="contact-subject" placeholder="Subject"/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn alazea-btn mt-15">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </section>
        </>
    );
}

export default Home;
