import React from 'react';

function SFooter() {
  return (		
    <footer className="footerArea bg-img" style={{"backgroundImage": `url(${process.env.PUBLIC_URL +'/img/bg-img/3.JPG'})`}}>
        <div className="main-footer-area">
            <div className="container">
                <div className="row mt-3">
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="single-footer-widget">
                            <div className="footer-logo mb-30">
                                <a href="#"><img src={`${process.env.PUBLIC_URL +'/img/core-img/logo.png'}`} alt=""/></a>
                            </div>                      
                            <div className="social-info">
                                <a href="https://twitter.com/strike_motor"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                <a href="https://www.instagram.com/strike_motor/"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="single-footer-widget">
                            <div className="widget-title mb-2 mt-1">
                                <img src={`${process.env.PUBLIC_URL +'/img/core-img/smallcontact.png'}`}/>
                            </div>

                            <div className="contact-information">
                                <p><span>Email:</span> info@strikemotor.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="border-line"></div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="copywrite-text">
                            <p>&copy;
                                Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved Strike
  Motor                           </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
}

export default SFooter;
