import React from 'react';
import { Row, Col } from 'antd';

async function trySendTransaction(sendTransaction, connection, transaction, wallet) {
  let signature = "";
  if (wallet && transaction)
  {
      try {
        signature = sendTransaction(transaction, connection)
      } catch (e) {
          signature = "";
        console.error(e)
      }
  }
  return signature;
}

export default trySendTransaction;
