import {ACTIONS, HANDLER} from './actiontypes'

/*
 * action creators
 */
 

//shop
export const addToCart = text => ({
  type: ACTIONS.ADD_TO_CART,
  text
})
export const removeFromCart = text => ({
  type: ACTIONS.REMOVE_FROM_CART,
  text
})

export const clearCart = text => ({
  type: ACTIONS.CLEAR_CART,
  text
})

export const storeAddress = text => ({
  type: ACTIONS.STORE_ADDRESS,
  text
})


//orders
export const clearOrders = text => ({
  type: ACTIONS.CLEAR_ORDERS,
  text
})


export const popupWindow = text => ({
  type: ACTIONS.GET_OTHER_WINDOW,
  text
})

export const setWallet = text => ({
  type: ACTIONS.SET_WALLET,
  text
})

export const clearWallet = text => ({
  type: ACTIONS.CLEAR_WALLET,
  text
})

export const setTransaction = text => ({
  type: ACTIONS.SET_TRANSACTION,
  text
})

export const clearTransaction = text => ({
  type: ACTIONS.CLEAR_TRANSACTION,
  text
})


