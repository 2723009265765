import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
//import monitorReducersEnhancer from './enhancers/monitorReducers'
import loggerMiddleware from './middleware/logger'
import rootReducer from './reducers'

//middleware for websocket
import levelThunker from './middleware/levelThunker'


export default function configureStore(preloadedState) {
  const isDev = window.location.host === "localhost:3000";
  const middlewares = isDev ? [thunkMiddleware, levelThunker, loggerMiddleware]
                            : [thunkMiddleware, levelThunker];
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = compose(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  return store
}
