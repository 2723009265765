import { combineReducers } from 'redux';
import {ACTIONS, STATUS} from './actions/actiontypes';
import _ from "lodash";

const dataStore = {
    restserver: "https://dropcopy.io/goodshop"
    ,cart:[]
    ,cartQty:0
    ,payment: {
        wallet: null
        ,transaction: null
    }
    ,address: {}
};

function address(state = dataStore.address, action) 
{     
  switch (action.type) 
  {
    case ACTIONS.STORE_ADDRESS:
      return action.text;
    default:
      return state;
  }
}

function restserver(state = dataStore.restserver, action) 
{     
  switch (action.type) 
  {
    default:
      return state;
  }
}

function cartQty(state = dataStore.cartQty, action) 
{     
  let count = state;
  switch (action.type) 
  {
    case ACTIONS.ADD_TO_CART:
        count += action.text.qty;
        return count;
    case ACTIONS.REMOVE_FROM_CART:
        count -= action.text.qty;
        return count;
    case ACTIONS.CLEAR_CART:
    return 0;
    default:
      return state;
  } 
}

function cart(state = dataStore.cart, action) 
{     
  switch (action.type) 
  {
    case ACTIONS.ADD_TO_CART:
		let newData = _.cloneDeep(state);
        let previous = newData.filter((item)=>(item.id === action.text.id)
                                    &&
                                    (item.size === action.text.size));
        if (previous.length > 0)
        {
            previous[0].qty += action.text.qty;
        }
        else
        {
            newData.push(action.text);
        }
           
		return newData;
    case ACTIONS.REMOVE_FROM_CART:
		let newData1 = _.cloneDeep(state);
        let remaining = newData1.filter((item)=>(!((item.id === action.text.id)
                                    &&
                                    (item.size === action.text.size))));
		return remaining;
    case ACTIONS.CLEAR_CART:
    return [];
    default:
      return state;
  }
}

function payment(state = dataStore.payment, action) 
{     
  switch (action.type) 
  {
    case ACTIONS.SET_WALLET:
		let newData = _.cloneDeep(state);
        newData.wallet = action.text.wallet;
		return newData;
    case ACTIONS.CLEAR_WALLET:
		let newData2 = _.cloneDeep(state);
        newData2.wallet = null;
		return newData2;
    case ACTIONS.SET_TRANSACTION:
		let newData3 = _.cloneDeep(state);
        newData3.transaction = action.text;
		return newData3;
    case ACTIONS.CLEAR_TRANSACTION:
		let newData4 = _.cloneDeep(state);
        newData4.transaction = null;
		return newData4;
    default:
      return state;
  }
}


const rootReducer = combineReducers({
  cart
  ,payment
  ,address
  ,restserver
  ,cartQty
})

export default rootReducer;
