import '../App.css';
import CheckoutProduct from './checkoutproduct';
import {ProductList} from '../assets/productlist';
import { useSelector, useDispatch} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {Countries} from './country';
import {storeAddress} from "../actions/actionCreators";
import { Form, Input, Space, Button, Table, Select, Option,
    Row, Col, Tooltip, notification  } from 'antd';


function Address() {
    const dispatch = useDispatch();
    const cartQty = useSelector((state) => state.cartQty);
    const navigate = useNavigate(); // <-- use hook in component
    
    const saveAddress = values => {    
    let data = {"action":"storeAddress"
                ,"email":values.email
                ,"fname":values.fname
                ,"lname":values.lname
                ,"address1":values.address1
                ,"address2":values.address2
                ,"city":values.city
                ,"state":values.state
                ,"country":values.country
                ,"zip":values.zip
                };
     
    dispatch(storeAddress(data));
        navigate("/payment");
  };
  
    const returnToShop =()=>{
        navigate("/");
    };
    
  return (
                <div className="shoprow d-flex justify-content-around">               
                    <Form layout="vertical" hideRequiredMark
                    onFinish={saveAddress}>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="fname"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter firstname'
                              },
                            ]}
                          >
                            <Input placeholder="first name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="lname"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter lastname'
                              },
                            ]}
                          >
                            <Input placeholder="last name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter email'
                              },
                              {
                                type: 'email',
                                message: 'Please enter a valid email',
                              },
                            ]}
                          >
                            <Input placeholder="email" />
                          </Form.Item>
                        </Col>
                        </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="confirmemail"
                            dependencies={['email']}
                            validateFirst
                            rules={[
                              {
                                required: true,
                                message: 'Please confirm email'
                              },
                              {
                                type: 'email',
                                message: 'Please enter a valid email',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('email') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('The two emails that you entered do not match!'));
                                },
                              }),
                            ]}
                          >
                            <Input placeholder="confirm email" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="address1"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter address'
                              },
                            ]}
                          >
                            <Input placeholder="address1" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="address2"
                            rules={[
                              {
                                required: false,
                                message: 'Please enter address'
                              },
                            ]}
                          >
                            <Input placeholder="address" />
                          </Form.Item>
                        </Col>
                      </Row>                      
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter city'
                              },
                            ]}
                          >
                            <Input placeholder="city" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="state"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter state/province'
                              },
                            ]}
                          >
                            <Input placeholder="state" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="country"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter country'
                              },
                            ]}
                          >
                          <Select 
                          style={{ width: 280 }}
                          options={Countries}
                          placeholder="Country"
                        />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="zip"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter zip/postcal code'
                              },
                            ]}
                          >
                            <Input placeholder="zip" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Space>
                        <Button onClick={returnToShop}>Cancel</Button>
                        <Button htmlType="submit" disabled={cartQty===0}>
                          Confirm
                        </Button>
                        </Space>
                      </Row> 
                        {
                            (cartQty===0) ?
                            (
                            <Row className="disabledtext justify-content-md-center mt-3">
                            Confirm button is disabled because your cart is empty
                            </Row>
                            )
                            :<span/>
                        }
                    </Form>
                </div>
  );
}

export default Address;
