import '../App.css';
import CheckoutProduct from './checkoutproduct';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {useNavigate} from "react-router-dom";
import {clearCart} from "../actions/actionCreators";

import { Space, Button, Spin, Alert  
    ,Row, Col, notification } from 'antd';
import { Keypair, Transaction } from "@solana/web3.js";

import trySendTransaction from "../utils/sendWalletTransaction";
import * as shortvec from '../utils/shortvec-encoding.ts';
import bs58 from 'bs58';

function Payment() {
    let cartQty = 0;
    let totalCost = 0;
    const cart = useSelector((state) => state.cart);
    const address = useSelector((state) => state.address);
    cart.forEach((item)=>{
        cartQty += item.qty;
        totalCost += (item.qty * item.cost);
    })
        
    const { publicKey, sendTransaction, disconnect } = useWallet();
    const disableCheckout = publicKey === null;
    const navigate = useNavigate(); // <-- use hook in component
    const dispatch = useDispatch();  
    const { connection } = useConnection();
    const transaction = useSelector((state) => state.payment.transaction);
    const server = useSelector((state) => state.restserver);
    const [api, contextHolder] = notification.useNotification();
    
    const [popUpMessage, setpopUpMessage] = useState(<span/>);
    const [reference, setReference] = useState("");
    const [paymentSignature, setPaymentSignature] = useState("");
    const processTransaction=(transaction)=>{
        if (transaction !== null) 
        {
            if (!publicKey)
            {
                //alert("wallet disconnected!");
                api.warning({
                      message: `Wallet disconnected`,
                      description:`Your wallet has auto disconnected`,
                      placement: 'bottomLeft',
                });
            }
            else
            {
                const VERSION_PREFIX_MASK = 0x7f;
                const SIGNATURE_LENGTH_IN_BYTES = 64;
                const a = Buffer.from(transaction.transactiondata, 'base64');
                 // Slice up wire data
                let byteArray = [...a];
                
                const signatureCount = shortvec.decodeLength(byteArray);
                let signatures = [];
                for (let i = 0; i < signatureCount; i++) {
                  const signature = byteArray.slice(0, SIGNATURE_LENGTH_IN_BYTES);
                  byteArray = byteArray.slice(SIGNATURE_LENGTH_IN_BYTES);
                  signatures.push(bs58.encode(Buffer.from(signature)));
                }

                const numRequiredSignatures = byteArray.shift();

                const transactiondata = Transaction.from(a);
                //const transactiondata = VersionedMessage.deserialize(a)
                setPopUpSpinnerWaiting();
                const signature = trySendTransaction(sendTransaction, connection, transactiondata, publicKey);
                if (signature === "")
                {
                    api.error({
                          message: `Error processing payment`,
                         description:`Could not get signature from wallet confirmation`,
                          placement: 'bottomLeft',
                    });                    
                }
                else
                {
                    setPaymentSignature(signature);
                }
            }
        } 
    }
    
    const setPopUpSpinnerWaiting =()=>{
        setpopUpMessage(
            <Row className="justify-content-md-center">  
                <Space direction="vertical">
                    <Spin tip="processing..." size="large" style={{color:'#FFF' }}>                            
                      <Alert
                        message="Confirming payment"
                        description="We are checking blockchain for payment confirmation"
                        type="info"
                      />
                    </Spin>
               </Space>
            </Row>
        );
    }
    
    const setPopUpSpinnerConfirmation =(values)=>{
        const desc = `Your reference number is ${reference}`
        setpopUpMessage(
            <Row className="justify-content-md-center">  
                <Space direction="vertical">                        
                      <Alert
                        message="Payment Confirmed"
                        description={desc}
                        type="info"
                      />
               </Space>
            </Row>
        );
    }
    
    const createTransaction =(values)=>{
        if (!publicKey) {
            console.log("No wallet found");
          return;
        }
        if (totalCost < 1)
        {
            return;
        }
		if (address.hasOwnProperty("zip"))
		{	
            let walletData = {"wallet" : publicKey};
            // Generate the unique reference which will be used for this transaction
            let ref = Keypair.generate().publicKey;
            setReference(ref);
            let data = {"action":"createPaymentTransaction"
                        ,"shopname":"STRIKE_MOTOR"
                        ,"cart":cart
                        ,"address":address
                        ,"reference":ref
                        ,"buyeraccount": publicKey
            };
             
            createPaymentTransaction(data);     
        } 
    }
    
    async function createPaymentTransaction(data) {
      try {
        const response = await fetch(server, {
          method: "POST",
        //  mode:"no-cors",
       //   cache: "no-cache",
       //   redirect: "follow",
       //credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        const result = await response.json();
        if (result.hasOwnProperty("transactiondata"))
        {
            processTransaction(result);
            //checkConfirmation();
        }
        else
        {            
            //alert("wallet disconnected!");
            
            api.error({
                  message: `Error processing payment`,
                 description:`${result.message}`,
                  placement: 'bottomLeft',
            });
        }
        
      } catch (error) {
        console.error("Error:", error);
      }
    }
    
    useEffect(() => {
        if (paymentSignature !== "")
        {
            checkConfirmation();
        }
    });
    const checkConfirmation =()=>{
        try
        {
         setTimeout(async () => {
             
            let data = {"action":"confirmPayment"
                        ,"reference":reference
                        ,"buyeraccount": publicKey
            };
             
            if (publicKey)
            {
                const response = await fetch(server, {
                  method: "POST",
                //  mode:"no-cors",
               //   cache: "no-cache",
               //   redirect: "follow",
               //credentials: "same-origin",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(data),
                });

                const result = await response.json();
                if (result.hasOwnProperty("status") && result.status ==="success"
                    && result.hasOwnProperty("paymentstatus") && result.paymentstatus ==="confirmed")
                {                
                    disconnect();
                    setPopUpSpinnerConfirmation();
                }
                else
                {
                    checkConfirmation();
                }
            }
          }, 1000);
        }
        catch (error)
        {
            console.error("Error:", error);
        }
    }
    
    const returnToShop =()=>{
        disconnect();
        dispatch(clearCart());
        navigate("/");
    };
    
    return (
        <>        
      {contextHolder}
            <section>
                <div className="titleArea d-flex align-items-center justify-content-center mb-30">
                    <h2>PAYMENT</h2>
                </div>       
                <div className="shoprow pt-1 justify-content-center">      
                    <br/><span className="cart"> {cartQty} items in cart <span className="price"> [ ${totalCost} GUSD ]</span></span>
                </div>  
            </section>
            <section className="banner-area relative  pt-50">
                <div className="container">
                <hr className="headerHR"/> 
                <div className="flex-row d-flex justify-content-around">  
                    <div className="col">
                        <span className="tableHDR">Item</span>
                    </div>  
                    <div className="col">
                        <span className="tableHDR">Quantity </span>
                    </div> 
                    <div className="col">
                        <span className="tableHDR"> Price ($GUSD)</span>
                    </div>  
                    <div className="col">
                        <span className="tableHDR"> Total ($GUSD)</span>
                    </div>  
                </div> 
                <hr className="headerHR"/> 
                {                
                    cart.map((items, key)=>{
                        return (
                        <React.Fragment key={key}>
                            <div className="flex-row d-flex justify-content-around" key={key}>
                                <CheckoutProduct removeable={false} item={items}/>
                            </div>
                            <hr className="itemHR"  key={"a"+key}/> 
                        </React.Fragment>
                    )})
                }
                <hr className="headerHR"/> 
                <div className="flex-row d-flex justify-content-around" key={"total1"}>  
                    <div className="col">
                        <span className="tableHDR">Total</span>
                    </div>  
                    <div className="col">
                        <span className="tableHDR">{cartQty} </span>
                    </div> 
                    <div className="col">
                        <span className="tableHDR"> - </span>
                    </div>  
                    <div className="col">
                        <span className="tableHDR"> {totalCost}</span>
                    </div>  
                </div> 
                <hr className="headerHR"/> 
                <div className="row pt-50 justify-content-center">                
                        <h3 className="h3 mb-15 text-black text-uppercase">Address</h3> 
                </div> 
                <div className="flex-row d-flex justify-content-center mb-20">  
                    <div>
                        <span>{address.fname} {address.lname}</span><br/>
                        <span>{address.email} </span><br/>
                        <span>{address.address1} </span><br/>
                        <span>{address.address2}</span><br/>
                        <span>{address.city}, {address.state} {address.zip}</span><br/>
                        <span>{address.country} </span><br/>
                    </div> 
                </div>
                <div className="flex-row d-flex justify-content-center mb-20">  
                        <h5 className="h5">Please confirm the details above and the connect your wallet to pay.</h5>
                </div>
                <Row className="justify-content-center mt-10 mb-2">          
                      <WalletMultiButton className='paymentButton' placeholder="Deposit"/> 
                </Row> 
                {popUpMessage}                      
                <Row className="justify-content-center">                
                    <Space className="pl-4 mt-4 mb-2">
                            <Col>
                              <Button type="primary" onClick={createTransaction} htmlType="submit" disabled={disableCheckout}>Checkout</Button>
                            </Col>
                            <Col className="pl-2">
                              <Button type="primary" onClick={returnToShop}>Cancel</Button>
                            </Col>
                    </Space>
                </Row>  
                {
                    disableCheckout ?
                    (
                    <Row className="flex-row disabledtext justify-content-center mb-2">
                    <span>Checkout button is disabled because you haven't connected a wallet</span>
                    </Row>
                    )
                    :<span/>
                }
                </div>
            </section>
    </>
  );
}

export default Payment;
