import React, { useState } from 'react';
import {useDispatch} from 'react-redux';
import {removeFromCart} from "../actions/actionCreators";

function CheckoutProduct({removeable, item}) {
    
    const [qty, setQty] = useState(0);
    const dispatch = useDispatch();
    
    const removeItem=()=>{
        dispatch(removeFromCart(item));
    }
    
  return (
        <>
        {
            removeable ?
            (
                <div className="col">
                    <span className="itemDelete lnr lnr-cross-circle" onClick={removeItem}></span>
                </div>
            ) : <span/>  
        }
                <div className="col">
                    <span>{item.unitName}</span>
                </div> 
                <div className="col"> 
                    <span>{item.qty}</span>
                </div> 
                <div className="col"> 
                    <span>{item.cost}</span>
                </div> 
                <div className="col"> 
                    <span>{item.qty* item.cost}</span>
                </div> 
        </>
  );
}

export default CheckoutProduct;
