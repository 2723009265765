export const ProductList = {
  'founder': {
    id: 'founder',
    name: 'Founders Edition - Reservation',
    unitName: "Founder's Edition - Reservation",
    description: "The Founder's Edition is a limited run of 100 motorcycles with our highest specifications. Each motorbikes will feature a unique custom artwork making each one  unique in it's own right. In addition to the ownership token, each Founder's Edition will be issued a unique NFT making them truly one of a kind. Reservation price is fully refundable.",
    img: `${process.env.PUBLIC_URL +'/img/bg-img/founders.JPG'}`,
    reserve: 100,
    cost: 15000,
  }
}
