import '../App.css';
import {useNavigate} from "react-router-dom";

function About() {
    const navigate = useNavigate(); // <-- use hook in component
    
    return (
        <>    
        <section className="about-us-area">
            <div className="titleArea d-flex align-items-center justify-content-center mb-30">
                        <img src={`${process.env.PUBLIC_URL +'/img/core-img/about.png'}`}/>
            </div>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-15">
                        <img src={`${process.env.PUBLIC_URL +'/img/core-img/climate.png'}`}/>
                         <p>We got tired of talking about climate change. In fact, we got tired of listening to others talk about climate and doing very little about it.<br/>
                         We knew that in order to make a real impact, we had to build electric vehicles that were not just pleasing to the eye but pleasing to the pocket as well.
                         </p>
                         <p>We think we nailed the beauty part with the M5 motorcycle, if we may say so ourselves. However, we accept that beauty is subjective. What IS objective is the price. There is no competition at our price point. Not even close.
                       </p> 
                        <img src={`${process.env.PUBLIC_URL +'/img/core-img/social.png'}`}/>
                         <p>We believe the world is better when everyone is treated equally. This equality spans education, healthcare, jobs, housing and the various arms of the justice system.
                         Sometimes, to properly address equality, the first step is to create equity. An equitable system is one that recognizes that some communities will need a little more help than others for there to be true equality.</p>
                         <p>Ultimately, it all begins and ends with love. Love means wanting good things for others but even more importantly, it requires that we treat others like we want to treated.
                       </p> 
                        <img src={`${process.env.PUBLIC_URL +'/img/core-img/living.png'}`}/>
                         <p>We believe that with the advancements made by the human race, NO ONE today should live in poverty wondering were their next meal, if any, will come from. 
                         It is unconscionable that children, out of no fault of their own, but simple by an unlucky draw of where they are born, are faced with food insecurity and health challenges from illnesses that have simple cures.</p>
                          <p>We also believe that it is unconscionable for anyone to work for an income that is below a living wage. We are huge proponents of hard work, but that hard work should be accompanied by a fair wage.                          
                       </p> 
                        <img src={`${process.env.PUBLIC_URL +'/img/core-img/commitment.png'}`}/>
                         <p>Profit is good, but what good is profit when you can't use it change the world for the better? <br/>
                         To this end, we are commiting a significant portion of our pre-tax profit to charities that reflect our beliefs as well as courses that help make our world a better place.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default About;
