import '../App.css';
import CheckoutProduct from './checkoutproduct';
import SHeader from './header';
import SFooter from './footer';
import Address from './address';
import {ProductList} from '../assets/productlist';
import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import {clearCart} from "../actions/actionCreators";

function Checkout() {
    let cartQty = 0;
    let totalCost = 0;
    const dispatch = useDispatch(); 
    const cart = useSelector((state) => state.cart);
    cart.forEach((item)=>{
        cartQty += item.qty;
        totalCost += (item.qty * item.cost);
    })
    
    const removeAllItem=()=>{
        dispatch(clearCart());
    }
    
  return (
	<>
		<section>
            <div className="titleArea d-flex align-items-center justify-content-center mb-30">
                <h2>CHECKOUT</h2>
            </div>        
            <div className="shoprow pt-1 align-items-center justify-content-center">      
                <br/><span className="cart"> {cartQty} items in cart <span className="price"> [ ${totalCost} GUSD ]</span></span>
            </div>  
		</section>
		<section className="pt-50">
			<div className="container">
                <hr className="headerHR"/> 
                <div className="shoprow d-flex justify-content-around">  
                    <div className="col">
                    
                    <span className="itemDelete lnr lnr-cross-circle" onClick={removeAllItem}></span>
                    </div>   
                    <div className="col">
                        <span className="tableHDR">Item</span>
                    </div>  
                    <div className="col">
                        <span className="tableHDR">Quantity </span>
                    </div> 
                    <div className="col">
                        <span className="tableHDR"> Unit Price ($GUSD)</span>
                    </div>  
                    <div className="col">
                        <span className="tableHDR"> Total ($GUSD)</span>
                    </div>  
                </div> 
                <hr className="headerHR"/> 
                {                
                    cart.map((items, key)=>{
                        return (
                        <React.Fragment key={key}>
                            <div className="shoprow d-flex justify-content-around">
                                <CheckoutProduct removeable={true} item={items}/>
                            </div>
                            <hr className="itemHR"/> 
                        </React.Fragment>
                    )})
                }
                <hr className="headerHR"/> 
                <div className="shoprow d-flex justify-content-around">  
                    <div className="col">
                        <span className="tableHDR">Total</span>
                    </div>  
                    <div className="col">
                        <span className="tableHDR"> - </span>
                    </div>   
                    <div className="col">
                        <span className="tableHDR">{cartQty} </span>
                    </div> 
                    <div className="col">
                        <span className="tableHDR"> - </span>
                    </div>  
                    <div className="col">
                        <span className="tableHDR"> {totalCost}</span>
                    </div>  
                </div> 
                <hr className="headerHR"/> 
			</div>
            <div className="shoprow pt-50 align-items-center justify-content-center">                
                    <h3 className="h3 mb-15 text-black text-uppercase">Address</h3> 
            </div> 
		</section>           
		<section className="mb-5">
            <Address/>
		</section>
	</>
  );
}

export default Checkout;
